import { SVGProps, memo } from "react";

function Loading(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 11 12"
            fill="none"
            {...props}
            className={`mx-2 my-auto animate-spin ${props.className}`}
        >
            <path d="M5.77 2.7a1.35 1.35 0 100-2.7 1.35 1.35 0 000 2.7z" fill="#fff" />
            <path opacity={0.6} d="M5.77 11.539a.81.81 0 100-1.62.81.81 0 000 1.62z" fill="#fff" />
            <path opacity={0.9} d="M2.454 3.937a1.215 1.215 0 100-2.43 1.215 1.215 0 000 2.43z" fill="#fff" />
            <path opacity={0.5} d="M9.087 10.031a.675.675 0 100-1.35.675.675 0 000 1.35z" fill="#fff" />
            <path opacity={0.8} d="M1.08 7.12a1.08 1.08 0 100-2.16 1.08 1.08 0 000 2.16z" fill="#fff" />
            <path opacity={0.4} d="M10.46 6.58a.54.54 0 100-1.08.54.54 0 000 1.08z" fill="#fff" />
            <path
                opacity={0.7}
                d="M1.785 8.689a.944.944 0 101.334 1.336.944.944 0 00-1.334-1.336z"
                fill="#fff"
            />
            <path opacity={0.3} d="M9.087 3.128a.405.405 0 100-.81.405.405 0 000 .81z" fill="#fff" />
        </svg>
    );
}

const MemoLoading = memo(Loading);
export default MemoLoading;
