import { SVGProps, memo } from "react";

function InvertedFunnel(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 12 10" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.248.899v2.51a1.5 1.5 0 01-.439 1.06L3.62 5.66a4.922 4.922 0 012.546.506c.882.44 1.902.556 2.856.317l.143-.035L7.188 4.47a1.5 1.5 0 01-.44-1.061V.899a11.807 11.807 0 00-1.5 0zm2.25.069A.375.375 0 007.545.22 12.406 12.406 0 004.839.179c-.13.012-.259.026-.387.042a.375.375 0 00.046.747v2.441a.75.75 0 01-.22.53L1.133 7.086c-.81.81-.451 2.238.735 2.44 1.343.23 2.724.349 4.131.349 1.408 0 2.788-.12 4.131-.349 1.187-.202 1.546-1.63.736-2.44L7.718 3.939a.75.75 0 01-.22-.53V.968z"
                fill="#7A869B"
            />
        </svg>
    );
}

const MemoInvertedFunnel = memo(InvertedFunnel);
export default MemoInvertedFunnel;
