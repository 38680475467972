import { Mode } from "@/types/util";
import { Stripe, loadStripe } from "@stripe/stripe-js";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;

export const STRIPE_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (env === "production" ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) +
    "/stripe";

let stripePromise: Promise<Stripe | null>;

export const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);
    }
    return stripePromise;
};

export const createSubscription = async (mode: Mode, jwt: string) => {
    const res = await fetch(`${STRIPE_API_URL}/create-subscription`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ mode }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const fetchCustomerPaymentMethods = async (jwt: string, starting_after?: string) => {
    const res = await fetch(`${STRIPE_API_URL}/fetch-customer`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ starting_after }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const cancelSubscription = async (id: string, jwt: string) => {
    const res = await fetch(`${STRIPE_API_URL}/cancel-subscription`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ id }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

// export const addCardToCustomer = async (method_id: string, jwt: string) => {
//     const res = await fetch(`${STRIPE_API_URL}/add-method`, {
//         method: "POST",
//         headers: { jwt, "content-type": "application/json" },
//         body: JSON.stringify({ method_id }),
//     });

//     if (!res.ok) {
//         throw new Error("Failed to make request");
//     }

//     return await res.json();
// };

export const removeCardFromCustomer = async (method_id: string, jwt: string) => {
    const res = await fetch(`${STRIPE_API_URL}/detach-method`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ method_id }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};
