import { Mode } from "@/types/util";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;

export const PAYMENTS_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (env === "production" ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) +
    "/payments";

export const payWithCoupon = async (jwt: string, coupon: string) => {
    const res = await fetch(`${PAYMENTS_API_URL}/use-coupon`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ coupon }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const createNewPaymentEntry = async (
    jwt: string,
    payment_id: string,
    subscription_type: Mode,
    subscriptionId: string,
) => {
    const res = await fetch(`${PAYMENTS_API_URL}/create`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ payment_id, subscription_type, subscriptionId }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const updatePaymentEntry = async (jwt: string, payment_id: string, subscriptionId: string) => {
    const res = await fetch(`${PAYMENTS_API_URL}/update`, {
        method: "POST",
        headers: { jwt, "Content-Type": "application/json" },
        body: JSON.stringify({ payment_id, subscriptionId }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const retrieveSinglePaymentDetails = async (payment_id: string) => {
    const res = await fetch(`${PAYMENTS_API_URL}/retrieve-payment/${payment_id}`);
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }

    return res.json();
};

export const retrieveAllUserPayments = async (jwt: string) => {
    const res = await fetch(`${PAYMENTS_API_URL}/retrieve-user-payments`, {
        headers: { jwt },
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const retrieveMostRecentPayment = async (jwt: string) => {
    const res = await fetch(`${PAYMENTS_API_URL}/retrieve-most-recent-payment`, {
        headers: { jwt },
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};
