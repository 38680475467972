import { SVGProps, memo } from "react";

function AdFree(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 48 48"
            id="prefix__a"
            fill="#7A869B"
            stroke="#7A869B"
            strokeWidth={6}
            width="1em"
            height="1em"
            {...props}
        >
            <g id="prefix__SVGRepo_iconCarrier">
                <defs>
                    <style>
                        {".prefix__c{fill:none;stroke:#7a869b;stroke-linecap:round;stroke-linejoin:round}"}
                    </style>
                </defs>
                <circle id="prefix__b" className="prefix__c" cx={24} cy={24} r={21.5} />
                <path className="prefix__c" d="M39.201 39.201L8.799 8.799" />
            </g>
        </svg>
    );
}

const MemoAdFree = memo(AdFree);
export default MemoAdFree;
