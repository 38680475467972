import Modal from "@components/Modal";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { cancelSubscription, createSubscription } from "@/services/stripe";
import { Mode } from "@/types/util";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "@app/profile/subscription/CheckoutForm";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/form-elements/Button";
import MemoLoading from "@assets/SVG/Loading";
import cookie from "js-cookie";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);

export const PaymentInputModal = ({
    mode,
    show,
    modes,
    setMode,
    setShow,
    setShowPaymentFeedbackModal,
}: {
    mode: Mode;
    show: boolean;
    modes: Mode[];
    setMode: Dispatch<SetStateAction<Mode>>;
    setShow: Dispatch<SetStateAction<boolean>>;
    setShowPaymentFeedbackModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingNewSecret, setLoadingNewSecret] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState("");

    const options = {
        clientSecret: clientSecret!,
    };

    const jwt = cookie.get("stytch_session_jwt");

    useEffect(() => {
        if (show) {
            setLoading(true);
            createSubscription(mode, jwt as string)
                .then((data) => {
                    setSubscriptionId(data.subscriptionId);
                    setClientSecret(data.clientSecret);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            setLoadingNewSecret(true);
            // cancelSubscription(subscriptionId, jwt as string).then(() => {
            // we don't have to since these are automatically deleted after 24hrs
            createSubscription(mode, jwt as string).then((data) => {
                setSubscriptionId(data.subscriptionId);
                setClientSecret(data.clientSecret);
                setLoadingNewSecret(false);
            });
            // });
        }
    }, [mode]);

    if (!show) return <></>;

    return (
        <Modal show={show} setShow={setShow} className="rounded-xl px-0 pb-0 pt-0">
            {loading ? (
                <div className="relative flex h-44 items-center justify-center p-0">
                    <MemoLoading />
                </div>
            ) : clientSecret ? (
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm
                        subscriptionId={subscriptionId}
                        clientSecret={clientSecret}
                        mode={mode}
                        modes={modes}
                        setMode={setMode}
                        setShow={setShow}
                        loadingNewSecret={loadingNewSecret}
                        setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                    />
                </Elements>
            ) : (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setShow(false);
                    }}
                    className="flex w-[400px] flex-col items-center justify-center overflow-hidden rounded-lg bg-[#1C1F27] p-0"
                >
                    <div className="flex w-full flex-col items-center gap-2 px-4 pb-5 pt-4">
                        <div className="flex h-9 w-9 items-center justify-center bg-[#c83d4d]/10">
                            <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                        </div>
                        <div className="flex w-full flex-col items-center gap-2 px-0 pb-0 pt-2">
                            <h2 className="text-base text-white">Error Loading Payment Information</h2>
                            <p className="text-center text-xs font-light text-[#7A869B]">Please try again.</p>
                        </div>
                    </div>
                    <div className="flex items-start justify-center gap-2 px-4 pb-3 pt-0">
                        <Button
                            type="submit"
                            onClick={() => setShow(false)}
                            className="flex h-10 w-[180px] items-center justify-center gap-[10px] rounded-lg border-none bg-[#C83D4D] px-[10px] py-3"
                        >
                            <p className="text-xs font-normal text-white">Close this</p>
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};
