type UserName = {
    first_name?: string;
    last_name?: string;
    middle_name?: string;
};

export function getFullName(name: UserName | undefined): string {
    let fullName = "";

    if (name) {
        const { first_name, middle_name, last_name } = name;

        if (first_name && middle_name && last_name) {
            fullName = `${first_name} ${middle_name} ${last_name}`;
        } else if (first_name && middle_name) {
            fullName = `${first_name} ${middle_name}`;
        } else if (middle_name && last_name) {
            fullName = `${middle_name} ${last_name}`;
        } else if (first_name && last_name) {
            fullName = `${first_name} ${last_name}`;
        } else if (first_name) {
            fullName = first_name;
        } else if (middle_name) {
            fullName = middle_name;
        } else if (last_name) {
            fullName = last_name;
        }
    }

    return fullName || "";
}
