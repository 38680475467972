import Modal from "@components/Modal";
import { Button } from "@/components";
import MemoCrown from "@assets/SVG/Crown";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useRouter } from "next/navigation";

export const PaymentFeedbackModal = ({
    show,
    setShow,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) => {
    const router = useRouter();

    useEffect(() => {
        if (show) {
            setTimeout(() => router.push("/profile/subscription"), 3000);
        }
    }, [show]);

    return (
        <Modal
            showCloseButton={false}
            show={show}
            setShow={setShow}
            className="overflow-hidden px-0 pb-0 pt-0"
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    setShow(false);
                }}
                className="flex w-[400px] flex-col items-center justify-center overflow-hidden rounded-lg bg-[#1C1F27] p-0"
            >
                <div className="flex w-full flex-col items-center gap-1 px-4 pb-5 pt-4">
                    <div className="flex h-9 w-9 items-center justify-center bg-[#2F343F]">
                        <MemoCrown className="h-5 w-5" />
                    </div>
                    <div className="flex w-full flex-col items-center gap-1 px-0 pb-0 pt-2">
                        <h2 className="text-base text-white">Welcome to Pro!</h2>
                        <p className="text-center text-xs font-light text-[#7A869B]">
                            Congratulations on purchasing the pro plan.
                        </p>
                    </div>
                </div>
                <div className="flex w-full items-start justify-center gap-2 px-4 pb-3 pt-0">
                    <Button
                        type="submit"
                        onClick={() => setShow(false)}
                        className="flex h-10 w-full items-center justify-center gap-[10px] rounded-lg border-none bg-[#306CE8] px-[10px] py-3"
                    >
                        <p className="text-xs font-normal text-white">Okay, close this</p>
                    </Button>
                </div>
            </form>
        </Modal>
    );
};
