// userPaymentStore.js
import { proxy } from "valtio";

const userPaymentState = proxy({
    paymentData: {} as any,
    loading: true,
    error: null,
    subIsActive: true, //TODO: make true for now so all users can access all features.
});

export const paymentState = proxy({
    changed: false,
});

export default userPaymentState;
