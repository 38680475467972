import { SVGProps, memo } from "react";

function Crown(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 18 17" fill="none" {...props}>
            <path
                d="M1.334 3.333l3.5 2.333L8.322.783a.833.833 0 011.357 0l3.488 4.883 3.5-2.333a.833.833 0 011.29.792l-1.37 11.639a.833.833 0 01-.827.736H2.24a.833.833 0 01-.827-.736L.044 4.124a.833.833 0 011.29-.791zM9 11.499a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z"
                fill={props.fill || "#7A869B"}
            />
        </svg>
    );
}

const MemoCrown = memo(Crown);
export default MemoCrown;
